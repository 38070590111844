import React, { useState } from "react"
import { graphql } from 'gatsby'
import Layout from "../components/Layout"
import * as styles from '../styles/blog.module.scss'
import { Link } from "gatsby"

import leatest from '../images/blog/leatest.png'
import right from '../images/blog/chevron-down.png'
import Fade from 'react-reveal/Fade';
const Blog = ({ data }) => {
    const { edges } = data.allMarkdownRemark;
    const [currentpage, setCurrentpage] = useState(0)
    let count = 0;
    const eq = [];
    const pageNum = []
    function getPageCount() {
        count = Math.ceil(edges.length / 9)
        for (var i = 0; i < count; i++) {
            const now = edges.slice(0 + i * 9, 9 + i * 9)
            eq.push(now)
            pageNum.push(i)
        }
    }
    getPageCount()
    const turnPage = (e) => {
        const num = e.target.dataset.page * 1;
        setCurrentpage(num)
    }
    const turnPre = () => {
        currentpage === 0 ? setCurrentpage(count - 1) : setCurrentpage(currentpage - 1)
    }
    const turnNext = () => {
        currentpage === count - 1 ? setCurrentpage(0) : setCurrentpage(currentpage + 1)
    }
    return (
        <Layout >
            <section className={styles.blog}>
                <div className={styles.title}>
                    <Fade bottom >
                        <h2>Blog</h2>
                    </Fade>
                    <Fade bottom delay={200}>
                        <p>Record the pace of corporate development</p>
                    </Fade>
                    <div>
                        <span>Home / </span><span>Blog</span>
                    </div>
                </div>
                <div className={styles.leatest}>
                    <div className={styles.left}>
                        <div className={styles.msg}>
                            <Link to={`${edges[0].node.frontmatter.path}`}>LATEST BLOG</Link>
                            <div>{edges[0].node.frontmatter.date}</div>
                        </div>
                        <div className={styles.tit}>
                           {edges[0].node.frontmatter.title}
                        </div>
                        <div className={styles.des}>
                           {edges[0].node.frontmatter.description}
                            <span>
                                <Link to={`${edges[0].node.frontmatter.path}`}>
                                    View more <span><img src={right} alt="" /></span>
                                </Link>
                            </span>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <img src={require(`../static/markdown/${edges[0].node.frontmatter.name}.png`).default} alt="" />
                    </div>
                </div>
                <div className={styles.content} id='content'>
                    {

                        eq[currentpage].map((edge, index) => {
                            const { frontmatter } = edge.node;

                            return (
                                <>
                                    <Link to={frontmatter.path} key={frontmatter.path}>

                                        <img className={styles.iiimg} src={require("../static/markdown/" + frontmatter.name + ".png").default} alt='' />
                                        <div className={styles.text}>
                                            <h3>{frontmatter.title}</h3>
                                            <p className={styles.des}>{frontmatter.description}</p>
                                            <div className={styles.little}>
                                                <span>{frontmatter.date}</span> <p> View more <img src={right} alt="" /></p>
                                            </div>
                                        </div>
                                    </Link>
                                </>

                            )
                        })
                    }
                    {
                        eq[currentpage].length % 3 == 2 ? <a></a> :
                            eq[currentpage].length % 3 == 1 ?
                                <>
                                    <a></a>
                                    <a></a></> : <></>
                    }
                </div>
                <div className={styles.control}>
                    <div onClick={turnPre} > <Link to="#content" style={currentpage === 0 ? { display: 'none' } : {}}>Pre</Link> </div>
                    <ul onClick={turnPage}>
                        {
                            pageNum.map((value, index) => (
                                <li key={value} data-page={index} className={currentpage === index ? `${styles.pageNumActive}` : ''}>{value + 1}</li>
                            ))
                        }
                    </ul>
                    <div onClick={turnNext} > <Link to="#content" style={currentpage === pageNum.length - 1 ? { display: 'none' } : {}}>Next</Link> </div>
                </div>

            </section>
        </Layout >
    )
}

export const query = graphql`
  query{
      allMarkdownRemark (
        sort:{order:DESC,fields:[frontmatter___id]}){
        edges {
          node {
            frontmatter {
                    id
                    title
                    date
                    author
                    path
                    description
                    name
                    }
                }
            } 
        }   
  }
  `;
export default Blog

