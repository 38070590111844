// extracted by mini-css-extract-plugin
export var blog = "blog-module--blog--50+GC";
export var title = "blog-module--title--MqXSm";
export var leatest = "blog-module--leatest--O0-2l";
export var left = "blog-module--left--UpFjZ";
export var msg = "blog-module--msg--le0EC";
export var tit = "blog-module--tit--QLLPk";
export var des = "blog-module--des--+wxoK";
export var right = "blog-module--right--Ss6aw";
export var content = "blog-module--content--tqzK0";
export var iiimg = "blog-module--iiimg--a8M9l";
export var text = "blog-module--text--RKthT";
export var little = "blog-module--little--KM0ru";
export var control = "blog-module--control--p1dPv";
export var pageNumActive = "blog-module--pageNumActive--c-e86";